import React from "react"
import styled from "styled-components"
import getSingleImage from "../../../utils/getSingleImage"
import Scrollspy from "react-scrollspy"
import { Sticky } from "react-sticky"
import PageContainer from "../../pageContainer"
import { scrollToElementById } from "../../../utils/scrollToElementById"
import { topOffsetMediaQueries } from "../../../utils/topOffsetMediaQueries"

const StyledBackground = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.mainWhite};
  height: 63px;
  display: none;
  justify-content: space-between;
  flex-direction: row;
  align-content: baseline;
  @media(min-width: 768px){
    flex-direction: row;
    justify-content: center;
    margin-left: -5px;
    &.sticky {
      display: ${ ({ isOldBrowser }) => isOldBrowser === true ? "none" : "flex" };
      position: fixed;
      top: 52px;
      left: 0;
      width: 100%;
      z-index: 10;
    }
  }
  a {
    text-decoration: none;
   }
  ul {
    display: flex;
    justify-content: space-between;
    margin: 0 -10px;
    @media(min-width: 992px){
      margin: 0 180px;
    }
    @media(min-width: 1200px){
      margin: 0 225px;
    }
  }
  button{
    border: none;
    background: none;
    outline: none;
  }
  li {
    opacity: 0.5;
    cursor: pointer;
    &.is-current{
      opacity: 1; 
      p{
      display: none;
      @media(min-width: 1200px){
        display: flex;
        padding: 17px 0;
        background: none;
      }   
    }
  }
  }
`

const ImageTextWrapper = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
`

const StyledImage = styled.img`
  width: 60px;
  height: auto;
  transform: scale(0.5);
  cursor: pointer;
`

const StyledText = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.mainNavyBlue};
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  text-align: left;
  display: none;
  cursor: pointer;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 63px;
`

class ComponentsFloatingMenu extends React.Component {
  state = {
    topOffsetPixels: 1600,
  }

  updateDimensions() {
    const topOffsetPixels = topOffsetMediaQueries(window.innerWidth)
    this.setState({ topOffsetPixels })
  }

  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this))
  }

  render() {
    const { sections } = this.props
    const { topOffsetPixels } = this.state
    const sectionSlugs = sections.map(section => section.wybierzComponent && section.wybierzComponent[0] && section.wybierzComponent[0].slug)
    if (typeof window !== "undefined") {
      const isIE = false || !!document.documentMode;
      const isEdge = !isIE && !!window.StyleMedia;
      var isOldBrowser = isIE || isEdge;
    }

    return (

      <Sticky topOffset={topOffsetPixels}>
        {({ isSticky }) => (<StyledBackground isOldBrowser={isOldBrowser} className={isSticky ? "sticky" : ""}>
          {!isOldBrowser && <PageContainer>
             <Scrollspy items={sectionSlugs} currentClassName="is-current" offset={-90}>
              {sections && sections.map((section, key) => {
                const component = section.wybierzComponent && section.wybierzComponent[0]
                const componentData = component && component.archeeComponents[0]
                return (
                  <li key={key}>
                    <ImageTextWrapper onClick={() => scrollToElementById(component.slug)}>
                      <StyledDiv>
                        <StyledImage src={getSingleImage(componentData.piktogram)}/>
                      </StyledDiv>
                      <StyledDiv>
                        <StyledText>{componentData.nazwa}</StyledText>
                      </StyledDiv>
                    </ImageTextWrapper>
                  </li>
                )
              })}
            </Scrollspy>
          </PageContainer>}
        </StyledBackground>)}
      </Sticky>
    )
  }
}

export default ComponentsFloatingMenu





